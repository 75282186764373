@page {
	margin: 2.3cm; 
}

html {
    line-height: 1.5;
    font-family: Helvetica, sans-serif;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.87)
}

header {
	display: none;
}

main {
	margin-bottom: 40px;
}

strong {
	font-weight: bold;
}

.z-depth-2 {
	display: none;
}

#amiblu-logo {
	margin-top: 20px;
	
	& img {
		width: 100%;
		max-width: 200px;
	}
}

#amiblu-logo a:link:after { 
	content: " (" attr(href) ") ";
}

#flowtite-logo a:link:after {
	content: " (" attr(href) ") ";
}

#hobas-logo a:link:after {
	content: " (" attr(href) ") ";
}

.imprint {
	display: none;
}

ul {
	list-style-type: none;
}

ul {
    padding: 0
}

ul li {
    list-style-type: none
}

.about-table-head th {
	font-size: 2.28rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0;
    text-align: left;
    vertical-align: middle;
    font-weight: 400;
}

.projects-gallery {
	display: none;
}

.hide-on-small-only,
.hide-on-large-only {
	display: none;
}